:root {
    --primary-color: #ed1c24;
    --scrollbar-color: #d6787b;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../src/assests/fonts/Axiforma-Regular.woff');
}
@font-face {
    font-family: 'Axiforma-SemiBold';
    src: url('../src/assests/fonts/Axiforma-SemiBold.woff');
}

*,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family:
        'Axiforma',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.centerSpinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    height: 100svh;
}
.innerComponent {
    height: calc(100vh - 150px) !important;
    height: calc(100dvh - 150px) !important;
    height: calc(100svh - 150px) !important;
}
::selection {
    background: #ed1c24;
    color: #ffffff;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #ed1c2348;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 10px;
}
