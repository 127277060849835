$font-8: 0.525rem; // 8px / 14px
$font-10: 0.625rem; // 10px / 16px
$font-12: 0.75rem; // 12px / 16px
$font-14: 0.875rem; // 14px / 16px
$font-16: 1rem; // 16px / 16px
$font-18: 1.125rem; // 18px / 16px
$font-20: 1.25rem; // 20px / 16px
$font-22: 1.375rem; // 22px / 16px
$font-23: 1.7rem; // 24px / 86px
$font-24: 1.9rem; // 24px / 86px

/* colors */
$primary-color: #ed1c24;

@mixin AllMainHeadingAuth {
    font-size: clamp($font-14, 4vw, $font-22);
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.0125em;
    font-family: Axiforma-SemiBold;
    color: #000000;
    margin-bottom: 0;
}

@mixin AllMainHeading {
    font-size: clamp($font-14, 7vw, $font-24);
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.0125em;
    font-family: Axiforma-SemiBold;
    color: #000000;
    margin-bottom: 0;
}

@mixin AllFormSemiHeading {
    font-size: clamp($font-12, 6vw, $font-22);
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.0125em;
    font-family: Axiforma-SemiBold;
    color: #000000;
    margin-bottom: 0;
}

@mixin AllFormSmallSemiHeading {
    font-size: clamp($font-8, 6vw, $font-18);
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.0125em;
    font-family: Axiforma-SemiBold;
    color: #000000;
    margin-bottom: 0;
}

@mixin AllModalHeading {
    font-size: clamp($font-12, 6vw, $font-23);
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.0125em;
    font-family: Axiforma-SemiBold;
    color: #000000;
    margin-bottom: 0;
}

@mixin labelMixin($fontSize: $font-12, $fontWeight: 400, $color: black) {
    font-size: $fontSize;
    font-weight: $fontWeight;
    line-height: 1.5;
    letter-spacing: 0.0125em;
    font-family: Axiforma-SemiBold;
    color: $color;
}
