@import './styles/variables.scss';
.inputnumberWidth {
    width: 100%;
}
.map-container-main.leaflet-container {
    min-height: 350px;
    height: 60vh;
    width: 100%;
    border-radius: 20px;
    max-width: 100vw;
}
.maploader {
    width: 100% !important;
    height: 100% !important;
}
.map-container-main.leaflet-container .leaflet-control .leaflet-control-zoom-in,
.map-container-main.leaflet-container .leaflet-control .leaflet-control-zoom-out {
    width: 40px;
    height: 40px;
    line-height: 40px;
}
.map-container-main.leaflet-container .leaflet-control-geosearch.leaflet-geosearch-bar form {
    border: 1px solid #bdbcbc;
    border-radius: 10px;
}
.map-container-main.leaflet-container .leaflet-control-geosearch.leaflet-geosearch-bar .glass,
.map-container-main.leaflet-container .leaflet-control-geosearch.leaflet-geosearch-bar .reset {
    height: 40px;
    font-size: 14px;
    border: none;
    border-radius: 10px;
}
.map-container-main.leaflet-container .leaflet-control-geosearch.leaflet-geosearch-bar .results div {
    height: 30px;
}
.map-container-main.leaflet-container .leaflet-control-geosearch.leaflet-geosearch-bar .results div:hover {
    background: $primary-color;
    color: #fff;
    border: none;
    padding: 3px 10px;
    font-weight: 500;
    border-radius: 5px;
}
.cluster-icon {
    background-color: #333;
    height: 2em;
    width: 2em;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.2rem;
    box-shadow: 0 0 0px 5px #fff;
}
.skeleton-node-customise {
    min-width: 100%;
    min-height: calc(60vh - 100px);
    max-height: 100%;
}
.ant-modal-root .ant-modal-mask,
.ant-image-preview-root .ant-image-preview-mask {
    z-index: 1001;
}
.ant-modal-root .ant-modal-wrap,
.ant-image-preview-root .ant-image-preview-wrap {
    z-index: 1002;
}

.ant-input:focus,
.ant-input:hover,
.ant-input:focus-within,
.ant-input-number:focus,
.ant-input-number:hover,
.ant-input-number:focus-within,
.ant-select-selector:focus,
.ant-select-selector:hover,
.ant-input-outlined:hover,
.ant-select-focused,
.ant-select-focused .ant-select-selector,
.ant-radio-wrapper:hover,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-input-number:focus-within,
.ant-input-outlined:focus-within,
.ant-picker-outlined:focus,
.ant-picker-outlined:hover,
.ant-picker-outlined:focus-within,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-color-picker-trigger:hover,
.ant-color-picker-trigger:focus {
    box-shadow: none !important;
    border-color: #ffd8da !important;
}

// quilljs customise
.quill {
    .ql-container {
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        background: #ffffff;
        border-width: 1px;
        border-style: solid;
        border-color: #d9d9d9;
        min-height: 150px;
    }

    .ql-snow.ql-toolbar {
        display: block;
        background: #ffffff;
        border-color: #d9d9d9;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
    }
}
.policy-terms-condition-editor {
    .ql-container {
        min-height: calc(65vh - 100px) !important;
    }
}
.property-images{
    .ant-image{
        width: 100%;
    }
}