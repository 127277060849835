@import '../../../styles/variables.scss';
.container {
    position: relative;
    background: #ffd8da;
    overflow-x: hidden;

    .bgImg {
        width: 100%;
        height: 100vh;
        background: #ffd8da;
    }
    .signin {
        background: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px 0;
        width: 100%;
        min-height: 100vh;
        min-height: 100dvh;
        min-height: 100svh;
        height: 100%;
        .signInForm {
            width: 100%;
        }
        .heading {
            @include AllMainHeadingAuth;
            text-align: left;
            margin-bottom: 20px;
        }
        .termsConditionText {
            text-align: center;
        }

        .signInButton {
            width: 100%;
            border-radius: 24px;
            height: 46px;
        }
        .joinNowBtn {
            width: 100%;
            border-radius: 24px;
            height: 46px;
            color: grey;
            border: 1px solid rgb(192, 188, 188);
        }

        h4 {
            text-align: center;
        }
        .receiveOtpText {
            text-align: start;
            width: 46%;
            margin-bottom: 20px;
        }

        .resendOtpText {
            text-align: start;
            width: 75%;
            font-size: 14px;
            margin-bottom: 50px;
        }
    }
}

.heading {
    font-size: 28px;
    text-align: center;
}

.logoutBtns {
    width: 100px;
}

.forgotPassword {
    background-color: red;

    .forgotText {
        text-align: center;
    }

    .sendOtp {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.logOutMain {
    padding: 20px 0;
    .heading {
        @include AllModalHeading;
        max-width: 80%;
        margin: 15px auto;
        text-align: center;
    }
    .actionBtns {
        margin-top: 20px;
        button {
            width: 100%;
            max-width: 160px;
        }
    }
}

.inputBox {
}
